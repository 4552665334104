@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ol {
        list-style: decimal;
        padding-left: 1em;
    }
    ul {
        list-style: disc;
        padding-left: 1em;
    }
} 